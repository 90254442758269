import React, { FC } from 'react';
import cn from "classnames";
import styles from './ConferenceCard.module.scss';
import { CfpEvent } from "../../../data/srm/models/events.models";
import { LocaleMessageAs } from "../../LocaleMessage/LocaleMessageAs";
import { LocaleMessageFrom } from "../../LocaleMessage/LocaleMessageFrom";
import { useI18nLocaleFormat } from "../../../hooks/useI18nLocaleFormat";
import { cfpIsFuture, cfpIsPrivate, cfpIsPublic, cfpIsRestricted } from "../../../data/srm/srm.helpers";
import Link from 'next/link';
import { APPLICATION_ROUTES } from "../../../utils/routes";
import { LocaleDateRange } from "../../LocaleMessage/LocaleDateTime";
import { useDataContext } from "../../../data/DataContext";

interface ConferenceCardProps {
    className?: string;
    conference: CfpEvent;
    isPartnerFlag: boolean;
}

const ConferenceCard: FC<ConferenceCardProps> = ({ className, conference, isPartnerFlag }) => {
    const { localeFrom } = useI18nLocaleFormat();
    const { isInternalUser } = useDataContext().stores.authStore;

    const isPublic = cfpIsPublic(conference);
    const isFuture = cfpIsFuture(conference);
    const isPrivate = cfpIsPrivate(conference);
    const isRestricted = cfpIsRestricted(conference);

    const statusMessageId = isPublic
        // disable by https://jira.jugru.org/browse/SRM-474
        ? "" :
        isFuture ? "home.conference.future" :
            "home.conference.finish";

    return (
        <article className={cn(className, styles.conferenceCard)}>
            <div className={cn(styles.conferenceCard__logo)}>
                {
                    conference.logoUrl &&
                    <img
                        className={cn(styles.conferenceCard__image)}
                        src={conference.logoUrl}
                        alt={localeFrom(conference.description)}
                    />
                }
            </div>

            <p className={cn(styles.conferenceCard__dates)}>
                <LocaleDateRange
                    startDate={conference.period?.startsAt ? new Date(conference.period?.startsAt) : undefined}
                    endDate={conference.period?.endsAt ? new Date(conference.period?.endsAt) : undefined}
                    displayOnlySeason={!conference.datesDetermined}
                />
            </p>

            <h2 className={cn(styles.conferenceCard__title)}>
                <LocaleMessageFrom value={conference.title}/>
            </h2>

            <p className={cn(styles.conferenceCard__description)}>
                <LocaleMessageFrom value={conference.description}/>
            </p>

            {
                !isPartnerFlag &&
                <p className={cn(styles.conferenceCard__status)}>
                    <LocaleMessageAs id={statusMessageId as any}/>
                </p>
            }

            <div className={cn(styles.conferenceCard__links)}>
                {
                    (isPublic || ((isPrivate || isRestricted) && isInternalUser)) &&
                    <Link href={APPLICATION_ROUTES.main.createRouteForCfp(conference, isPartnerFlag)}>
                        <a className={cn(styles.conferenceCard__link, styles.conferenceCard__link_main)}>
                            <LocaleMessageAs id={"home.conference.submit"}/>
                        </a>
                    </Link>
                }
                {/*temporary disabled*/}
                {/*<a*/}
                {/*    className={cn(styles.conferenceCard__link, styles.conferenceCard__link_alt)}*/}
                {/*    href=""*/}
                {/*>*/}
                {/*    <LocaleMessageAs id={"home.conference.more"}/>*/}
                {/*</a>*/}
            </div>
        </article>
    );
}

export default ConferenceCard;
