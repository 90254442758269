import React, { FC } from 'react';
import cn from "classnames";
import styles from "./Conferences.module.scss";
import { CfpEvent } from "../../../data/srm/models/events.models";
import ConferenceCard from "../ConferenceCard/ConferenceCard";
import { cfpIsRestricted } from "../../../data/srm/srm.helpers";
import { useDataContext } from "../../../data/DataContext";

interface ConferencesProps {
    className?: string;
    cfpEvents: CfpEvent[];
    isPartnerFlag: boolean;
    isMeetupFlag: boolean;
}

const Conferences: FC<ConferencesProps> = ({ className, cfpEvents, isPartnerFlag, isMeetupFlag }) => {
    const { isInternalUser } = useDataContext().stores.authStore;

    const sortedCfpEvents = cfpEvents
        .sort((a, b) => new Date(a?.period?.startsAt) > new Date(b.period?.startsAt) ? 1 : -1);

    if (isMeetupFlag && !isInternalUser) {
        return null;
    }

    return (
        <section className={cn(className, styles.conferences)}>
            <div className={cn(styles.conferences__container)}>
                {
                    cfpEvents.length > 0 &&
                    <ul
                        className={cn(styles.conferences__list)}
                        aria-labelledby="conferences__label"
                    >
                        {
                            sortedCfpEvents.map(conference => {
                                    if (cfpIsRestricted(conference) && !isInternalUser) {
                                        return null;
                                    }
                                    return (
                                        <li
                                            className={cn(styles.conferences__item)}
                                            key={`li_${conference.id}`}
                                        >
                                            <ConferenceCard
                                                className={cn(styles.conferences__card)}
                                                conference={conference}
                                                isPartnerFlag={isPartnerFlag}
                                            />
                                        </li>
                                    )
                                }
                            )
                        }
                    </ul>
                }
            </div>
        </section>
    );
};

export default Conferences;
