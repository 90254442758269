import React, { FC } from "react";
import cn from "classnames";
import styles from "./HomeContent.module.scss";
import { CfpEvent } from "../../../data/srm/models/events.models";
import { LocaleMessageAs } from "../../LocaleMessage/LocaleMessageAs";
import Conferences from "../Conferences/Conferences";
import { DraftScope } from "../../../data/srm/models/draft.models";
import { filterMeetupEvents } from "../../../data/srm/srm.helpers";
import { useDataContext } from "../../../data/DataContext";
import { observer } from "mobx-react-lite";
import { useI18nLocaleFormat } from "../../../hooks/useI18nLocaleFormat";

interface HomeContentProps {
    cfpEvents: CfpEvent[];
    scope: DraftScope;
    workspaceOrSlugId: string;
}

const HomeContent: FC<HomeContentProps> = ({ cfpEvents, scope, workspaceOrSlugId }) => {
    const { authStore } = useDataContext().stores;
    const { localeAs } = useI18nLocaleFormat();
    const { isInternalUser, isLogged } = authStore;

    const isPartnerFlag = scope === "PARTNER";
    const isMeetupFlag = scope === "MEETUP";
    const isRegularFlag = scope === "REGULAR";

    if (isLogged === undefined) {
        return null;
    }

    if ((!isInternalUser) && (isPartnerFlag || isRegularFlag)) {
        return <p className={cn("p-mt-6 p-p-4", styles.homeContent__subTitle)}>
            <div dangerouslySetInnerHTML={{
                __html: localeAs(`home.redirect.${scope}`)
            }}/>
        </p>;
    }

    return (
        <div className={cn(styles.homeContent)}>
            {
                isMeetupFlag &&
                <div className={cn(styles.homeContent__container)}>
                    <p className={cn(styles.homeContent__subTitle)}>
                        <LocaleMessageAs id={"home.subtitle.meetup"}/>
                    </p>
                </div>
            }
            {
                (isInternalUser || isMeetupFlag) &&
                <Conferences
                    className={cn(styles.homeContent__conferences)}
                    cfpEvents={filterMeetupEvents(cfpEvents, scope)}
                    isPartnerFlag={isPartnerFlag}
                    isMeetupFlag={isMeetupFlag}
                />
            }
        </div>
    )
};

export default observer(HomeContent);
