import React, { FC } from 'react';
import { GetServerSideProps } from 'next';
import { CfpEvent } from "../../data/srm/models/events.models";
import { SrmApiService } from "../../data/srm/srm.api.service";
import Layout from "../../components/Layout/Layout";
import HomeContent from "../../components/Home/HomeContent/HomeContent";
import { getScope } from "../../../environment";
import { useIsBrowser } from "../../hooks/useIsBrowser";

export interface HomePageProps {
    cfpEvents: CfpEvent[];
    locale: string;
    host?: string;
    workspaceOrSlugId: string;
}

const Home: FC<HomePageProps> = (props) => {
    const { cfpEvents, workspaceOrSlugId } = props;
    const isBrowser = useIsBrowser();

    const scope = getScope(props.host);

    return (
        <Layout scope={scope} workspaceOrSlugId={workspaceOrSlugId}>
            {
                scope && isBrowser &&
                <HomeContent cfpEvents={cfpEvents}
                             scope={scope}
                             workspaceOrSlugId={workspaceOrSlugId}
                />
            }
        </Layout>
    )
}

export default Home;

export const getServerSideProps: GetServerSideProps<HomePageProps> = async (context) => {
    const locale = context.locale || 'ru';
    const workspaceOrSlugId = context.params.workspaceOrSlugId as string;

    const srmService = new SrmApiService(true);
    const cfpEvents = (await srmService.getOpenCfp(workspaceOrSlugId)).data;

    const { req } = context;
    let host: string | undefined;
    if (req) {
        host = req.headers.host;
    }

    return {
        props: {
            locale,
            cfpEvents,
            host,
            workspaceOrSlugId
        }
    }
}
